@tailwind base;
@tailwind components;
@tailwind utilities;





@media screen and (max-width:768px){
    .card{
        width:90% ;
        margin:0 auto
    }
    .card_wrapper{
        justify-content:center
    }
    .hero{
        flex-direction:column;
        width:90%;
    }
    .hero_left{
        width:100%;
    }
    .hero_right{
        width:100%;
        margin-top:50px;
    }
    .hero_right img{
        width:300px;
        height:300px;
        border-radius:50%;
    }
    .card_main_wrapper{
        width:95%;
    }
    .inner_wrapper{
        width:100%;
        height:auto
    }
}